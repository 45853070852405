import request from '../utils/request';

/**
 * 获取学院培训
 * @param query
 * @returns {AxiosPromise}
 */
export const getTrainingRecord = (query) => {
    return request({
        url: '/web/training/page',
        method: 'post',
        dataType: "json",
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        },
    });
};

/**
 * 导出
 * @param query
 * @returns {AxiosPromise}
 */
export const excel = (query) => {
    return request({
        url: '/web/training/excel',
        method: 'GET',
        params: query,
        responseType: 'arraybuffer'
    });
};